<script>
  import { ja as localeJa } from "date-fns/locale";

  import { deletePreUploadData } from "~/libs/stores";
  import { sortData, toggleSortIcon } from "~/libs/tableUtils";
  import { formatStringDate } from "~/libs/utils";
  import DeleteTrashIcon from "~/pages/Upload/DeleteTrashIcon.svelte";

  /**
   * 登録済みの出荷確定データ一覧を格納する配列
   * @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>}
   */
  export let results;
  /** @type {Function} */
  export let deletedResultsReplace;

  (() => {
    deletePreUploadData.set(results);
  })();

  $: if (results) {
    deletePreUploadData.set(results);
  }

  const columns = [
    { header: "", id: "trashIcon", accessor: (item) => item },
    {
      header: "切離し日",
      id: "toReceiveOn",
      accessor: (item) => item.toReceiveOn,
    },
    {
      header: "登録回",
      id: "sequentialNumber",
      accessor: (item) => item.sequentialNumber,
    },
    {
      header: "荷物数",
      id: "numberOfPackages",
      accessor: (item) => item.numberOfPackages,
    },
    {
      header: "最終更新日時",
      id: "createdAt",
      accessor: (item) =>
        formatStringDate(item.createdAt, "yyyy/MM/dd(E)HH:mm", {
          locale: localeJa,
        }),
    },
  ];

  /** @type {object | null} */
  let sortedColumn = null;
  /** @type {"asc" | "desc"} 検索結果の並び順が昇順か降順か */
  let sortOrder = "asc";

  /** @type {Array<import("~/libs/backendApi").ShippingReceiptUnit>} ソートされた検索結果 */
  $: sortedData = sortedColumn
    ? sortData(results, sortedColumn, sortOrder)
    : results;
</script>

<div class="shippingReceiptUnitTable">
  <div class="mdc-data-table">
    <div class="mdc-data-table__table-container">
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            {#each columns as column}
              <th
                class="mdc-data-table__header-cell"
                style="cursor: pointer;"
                on:click={() => {
                  [sortOrder, sortedColumn] = toggleSortIcon(
                    column,
                    sortedColumn,
                    sortOrder,
                  );
                }}
              >
                {#if column.id !== "trashIcon"}
                  <div class="th-item">
                    {column.header}
                    {#if sortedColumn?.id === column.id}
                      <span class="material-icons"
                        >{sortOrder === "asc"
                          ? "arrow_upward"
                          : "arrow_downward"}</span
                      >
                    {/if}
                  </div>
                {/if}
              </th>
            {/each}
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          {#if sortedData.length === 0}
            <tr class="mdc-data-table__row">
              <td class="mdc-data-table__cell no_data_note" colspan="6">
                該当するデータがありません。
              </td>
            </tr>
          {:else}
            {#each sortedData as row}
              <tr class="mdc-data-table__row">
                {#each columns as column}
                  {#if column.id === "trashIcon"}
                    <td class="mdc-data-table__cell">
                      <DeleteTrashIcon
                        record={column.accessor(row)}
                        {deletedResultsReplace}
                      />
                    </td>
                  {:else if column.id === "toReceiveOn"}
                    <td class="mdc-data-table__cell">
                      {formatStringDate(column.accessor(row), "yyyy/MM/dd(E)", {
                        locale: localeJa,
                      })}
                      {#if new Date().getTime() - new Date(column.accessor(row)).getTime() > 2 * 24 * 60 * 60 * 1000}
                        <span class="cautionMark">2日以上前</span>
                      {/if}
                    </td>
                  {:else if column.id === "sequentialNumber"}
                    <td class="mdc-data-table__cell number-cell">
                      {column.accessor(row).toLocaleString()}回目
                    </td>
                  {:else if column.id === "numberOfPackages"}
                    <td class="mdc-data-table__cell number-cell">
                      {column.accessor(row).toLocaleString()}個
                    </td>
                  {:else}
                    <td class="mdc-data-table__cell">
                      {column.accessor(row)}
                    </td>
                  {/if}
                {/each}
              </tr>
            {/each}
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</div>

<style lang="scss">
  .shippingReceiptUnitTable {
    :global(.mdc-data-table) {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      max-height: calc(100vh - 236px);
      overflow-x: auto;
      overflow-y: none;
    }

    :global(.mdc-data-table__table thead) {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .number-cell {
      text-align: right;
    }

    th {
      background-color: #eaf5ff;
      vertical-align: middle;
      font-size: small;

      .th-item {
        display: flex;
        position: relative;

        span {
          position: relative;
          margin-left: 3px;
          top: 3px;
          font-size: 18px;
          color: #5c5c5c;
        }
      }
    }

    td {
      vertical-align: middle;
      font-size: small;
      position: relative;
    }

    tr th:nth-child(1),
    tr td:nth-child(1) {
      width: 1px;
      padding-left: 4px;
      padding-right: 0;
    }

    .cautionMark {
      background-color: #bd362f;
      border-radius: 5px;
      color: #fff;
      display: inline-block;
      font-size: 85%;
      padding: 2px 5px;
      margin: 0 5px;
    }
  }
</style>
