<script>
  import Button from "@smui/button";
  import { getContext, onDestroy } from "svelte";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import TextFilter from "~/components/TextFilter.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import {
    editManagementResult,
    managementResultData,
    managementResultEditClose,
    messageDialogClose,
  } from "~/libs/stores";
  import { sortData, toggleSortIcon } from "~/libs/tableUtils";
  import ManagementResultEdit from "~/pages/Management/ManagementResultEdit.svelte";

  /**
   * @type {Array<import("~/libs/commonTypes").CustomedUserInfo>}
   */
  export let results;

  /** @type {import("~/libs/backendApi").GetCompaniesResponse}>} */
  export let companyNameList;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {import("svelte/store").Unsubscriber} */
  let managementResultEditCloseUnsubscriber;

  /** @type {import("svelte/store").Unsubscriber} */
  let messageDialogCloseUnsubscriber;

  let managementResultEdit;
  let target;
  let displayingDetails = false;
  let changeButtonDisabled = userContext.hasPermitUserManagementRole()
    ? false
    : true;
  let dialogComponent;
  let dialogTitle;
  let dialogMessage;
  let result;

  (() => {
    managementResultData.set(results);
  })();

  const columns = [
    { header: "", id: "updateButton", accessor: (item) => item },
    {
      header: "ユーザーID",
      id: "userName",
      accessor: (item) => item.userName ?? "",
    },
    {
      header: "表示名",
      id: "displayName",
      accessor: (item) => item.displayName ?? "",
    },
    {
      header: "メールアドレス",
      id: "emailAddress",
      accessor: (item) => item.emailAddress ?? "",
    },
    {
      header: "管理者専用メモ",
      id: "contactInfo",
      accessor: (item) => item.contactInfo ?? "",
    },
    {
      header: "アカウント状態",
      id: "disabled",
      accessor: (item) => {
        if (item.disabled === true) {
          return "無効";
        } else if (item.disabled === false) {
          return "";
        } else {
          return "";
        }
      },
    },
  ];

  managementResultEditCloseUnsubscriber = managementResultEditClose.subscribe(
    async (isClosed) => {
      if (displayingDetails && isClosed) {
        if ($editManagementResult) {
          await updatedResultsReplace($editManagementResult);
          editManagementResult.set(null);
        }
        displayingDetails = false;
        managementResultEdit = null;
        managementResultEditClose.set(false);
        if (result == "success" || result == "failed") {
          result = null;
          dialogComponent = MessageDialog;
        }
      }
    },
  );

  messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogTitle = null;
    dialogMessage = null;
    dialogComponent = null;
    messageDialogClose.set(false);
  });

  async function openWindow(result) {
    target = result;
    displayingDetails = true;
    managementResultEdit = null;
    managementResultEdit = await ManagementResultEdit;
  }

  function handleMessage(event) {
    result = event.detail.result;
    dialogTitle = event.detail.title;
    dialogMessage = event.detail.message;
  }

  /** @type {object | null} */
  let sortedColumn = null;
  /** @type {"asc" | "desc"} 検索結果の並び順が昇順か降順か */
  let sortOrder = "asc";

  /** @type {Array<import("~/libs/commonTypes").CustomedUserInfo>} フィルタリングされた検索結果 */
  $: filteredData = results;
  /** @type {Array<import("~/libs/commonTypes").CustomedUserInfo>} フィルタリング→ソートされた検索結果 */
  $: sortedData = sortedColumn
    ? sortData(filteredData, sortedColumn, sortOrder)
    : filteredData;

  /**
   * 選択肢もしくはテキストによるフィルタリング機能を適用する
   * @param {CustomEvent} event
   */
  function applyFilter(event) {
    filteredData = event.detail.values;
  }

  /**
   * 検索結果一覧に更新内容を反映する。
   * @param {import("~/libs/backendApi").UserInfo} updatedResult
   */
  async function updatedResultsReplace(updatedResult) {
    let targetIndex;
    for (targetIndex = 0; targetIndex <= results.length; targetIndex++) {
      if (results[targetIndex].id === updatedResult.id) {
        break;
      }
    }
    results.splice(targetIndex, 1, updatedResult);
    results = results;
    managementResultData.set(results);
  }

  onDestroy(() => {
    managementResultEditCloseUnsubscriber?.();
    messageDialogCloseUnsubscriber?.();
  });
</script>

<svelte:component this={dialogComponent} {dialogTitle} {dialogMessage} />
<svelte:component
  this={managementResultEdit}
  result={target}
  on:message={handleMessage}
  {companyNameList}
/>
<div class="mdc-data-table">
  <div class="mdc-data-table__table-container">
    <table class="mdc-data-table__table">
      <thead>
        <tr class="mdc-data-table__header-row">
          {#each columns as column}
            <th
              class="mdc-data-table__header-cell"
              style={column.id === "updateButton" ? "" : "cursor: pointer;"}
              on:click={() => {
                [sortOrder, sortedColumn] = toggleSortIcon(
                  column,
                  sortedColumn,
                  sortOrder,
                );
              }}
            >
              {#if column.id !== "updateButton"}
                <div class="th-item">
                  {column.header}
                  {#if sortedColumn?.id === column.id}
                    <span class="material-icons"
                      >{sortOrder === "asc"
                        ? "arrow_upward"
                        : "arrow_downward"}</span
                    >
                  {/if}
                </div>
                {#if column.id === "displayName"}
                  <!-- テキストによるフィルタリング機能 -->
                  <div class="filter-area">
                    <TextFilter
                      {results}
                      columnId={column.id}
                      on:filter={applyFilter}
                    />
                  </div>
                {/if}
              {/if}
            </th>
          {/each}
        </tr>
      </thead>
      <tbody class="mdc-data-table__content">
        {#if sortedData.length === 0}
          <tr class="mdc-data-table__row">
            <td class="mdc-data-table__cell no_data_note" colspan="6">
              該当するデータがありません。
            </td>
          </tr>
        {:else}
          {#each sortedData as row (row.id)}
            <tr class="mdc-data-table__row">
              {#each columns as column}
                {#if column.id === "updateButton"}
                  <td class="mdc-data-table__cell">
                    <Button
                      style="height: 30px; width: 60px; margin-left: 10px;"
                      touch
                      variant="unelevated"
                      on:click={() => {
                        openWindow(column.accessor(row));
                      }}
                      bind:disabled={changeButtonDisabled}>変更</Button
                    >
                  </td>
                {:else if column.id === "disabled"}
                  <td class="mdc-data-table__cell" style="color: #F90404;">
                    {column.accessor(row)}
                  </td>
                {:else if column.id === "contactInfo"}
                  <td class="mdc-data-table__cell contact-info-cell">
                    {column.accessor(row)}
                  </td>
                {:else}
                  <td class="mdc-data-table__cell">
                    {column.accessor(row)}
                  </td>
                {/if}
              {/each}
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 236px);
    overflow-x: auto;
    overflow-y: none;
  }

  .mdc-data-table__table thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .contact-info-cell {
    min-width: 200px;
    max-width: 420px;
    padding: 8px 16px;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  th {
    background-color: #eaf5ff;
  }

  th {
    vertical-align: middle;
    font-size: small;

    .th-item {
      display: flex;
      position: relative;

      span {
        position: relative;
        margin-left: 3px;
        top: 3px;
        font-size: 18px;
        color: #5c5c5c;
      }
    }
  }

  td {
    vertical-align: middle;
    font-size: small;
  }

  tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }
</style>
