<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { createEventDispatcher, onMount } from "svelte";

  /** @type {import("~/libs/commonTypes").DetailedShipment} */
  export let shipment;

  /** 登録解除を行うことの了承チェック @type {boolean} */
  let agreeRegisterationChecked = false;

  let dispatch = createEventDispatcher();

  // ページの初期化処理
  onMount(() => {
    onInputChange();
  });

  /**
   * 入力内容が変更されたとき、SearchResultUpdatePattern.svelteにイベントを発行する。
   */
  function onInputChange() {
    /** @type {boolean} 登録に必要な条件が全てそろっているか判定するフラグ */
    let isAllConditionsMet = false;
    if (shipment?.numberOfPackages > 1) {
      if (agreeRegisterationChecked) {
        // 個口が2個以上の場合、了承チェックがされていれば、登録に必要な条件が全てそろっていると判定
        isAllConditionsMet = true;
      }
    } else {
      // 個口が1個の場合は、了承チェックが不要
      isAllConditionsMet = true;
    }

    /** @type {import("~/libs/backendApi").UpdateShipmentEvent} 更新する荷物情報 */
    const updateShipmentEvent = {
      trackingNumber: shipment.trackingNumber,
      status: shipment.status,
      version: shipment.version,
    };
    updateShipmentEvent.notActuallyReceived = false;

    // イベントを発行
    dispatch("inputChange", {
      isAllConditionsMet,
      updateShipmentEvent,
    });
  }
</script>

<div class="noteArea">
  <p>
    <strong>引渡予定の荷物リストに含まれていたが引渡時に存在しなかった</strong
    >荷物として登録された状態を解除します。<br />
    見つかった荷物を配達する場合にのみ解除を行ってください。<br />
  </p>
  {#if shipment?.numberOfPackages > 1}
    <!-- 複数個口の場合 -->
    <div class="alertMessage">
      <span class="material-icons .md-18"> warning_amber </span>
      <p>
        複数口（全{shipment.numberOfPackages}個）の荷物です。<br />
        一部を登録解除する場合は通信欄に詳細を記載してください。
      </p>
    </div>
    <FormField>
      <Checkbox
        bind:checked={agreeRegisterationChecked}
        on:change={onInputChange}
      />
      <span slot="label">同意して登録の解除を行う</span>
    </FormField>
  {/if}
</div>

<style lang="scss">
  .noteArea {
    .alertMessage {
      margin: 6px 0;
      padding: 8px 4px 8px 16px;
      color: #672b2a;
      background-color: #ffe7e7;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 10px;
      line-height: 1.4em;
    }
    .material-icons {
      font-size: 22px;
      color: #d74141;
    }
    :global(.mdc-form-field) {
      margin-top: 8px;
      margin-left: 6px;
    }
  }
</style>
